import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledWebsiteHostingBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [50, 70]
  })
)

export const styledWebsiteHosting = css`
  ${styledWebsiteHostingBps};
`

/* HEADER */
export const H4HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    marginTop: [8],
    marginBottom: [16],
    letterSpacing: [0.25],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const H5HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    marginTop: [8],
    marginBottom: [16],
    letterSpacing: [0.25],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const spanBps = css(
  mq({
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const lineBorderBps = css(
  mq({
    maxWidth: [328, 422, 680, 680]
  })
)

export const header = css`
  display: flex;
  text-align: center;
  span {
    ${spanBps};
    text-transform: uppercase;
    font-size: 11.8px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
    display: grid;
  }
  h5 {
    ${H5HeaderBps};
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    ${PHeaderBps};
    margin-bottom: 16px;
  }
`

export const content = css`
  display: flex;
  text-align: center;
  h5 {
    ${H5HeaderBps};
    color: rgba(0, 0, 0, 0.87);
  }
`

export const lineBorder = css`
  ${lineBorderBps};
  height: 1px;
  border-bottom: solid 1px rgba(217, 222, 227, 0.5);
  margin: 0 auto;
  margin-bottom: 40.5px;
`