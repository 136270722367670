import React from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Hr from '../components/Hr'
import H4 from '../components/H4'
import H5 from '../components/H5'
import Paragraph from '../components/Paragraph'

import { Container } from '../components/styles/Layout.styles'
import Button from '../components/Button/Button'
import { 
  styledWebsiteHosting,
  header,
  content
} from '../components/styles/WebsiteHosting.styles'
import HospedagemDeSitesForm from '../components/ContactForm'

const fields = [
  {
    label: 'Nome Completo',
    name: 'your-name',
    type: 'text',
    required: true
  },
  {
    label: 'Email',
    name: 'your-email',
    type: 'email',
    required: true
  },
  {
    label: 'Telefone',
    name: 'your-phone',
    type: 'text',
    required: true,
    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  {
    label: 'Assunto',
    name: 'your-subject',
    type: 'text',
    required: true
  },
  {
    label: 'Selecione um departamento',
    name: 'your-sector',
    type: 'select',
    options: [
      { value: 1, label: 'Item A' },
      { value: 2, label: 'Item B' },
      { value: 3, label: 'Item C' }
    ],
    required: true
  },
  {
    label: 'Mensagem',
    name: 'your-message',
    type: 'text',
    multiline: true,
    rows: 6,
    required: true
  }
]

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledWebsiteHosting}>
      <Container>
        <Row
          flexDirection={['column', 'row']}
          css={header}
        >
          <Column
            width={[1]}
            mb={50}
          >
            <H4>
              <span>Hospedagem de Sites</span>
              Que tal um parceiro?
            </H4>
            <Paragraph>Adicionando um serviço de armazenamento remoto para o seu serviço de hosting, seus dados serão armazenados em um local remoto seguro em nosso data center, ajudando a maximizar a redundância e melhorando o desempenho global de seu servidor atual.</Paragraph>
            <Paragraph>A HostDime não mais comercializa planos de hospedagem de sites e revenda de hospedagem, no entanto temos clientes parceiros que oferecem esses e outros serviços.</Paragraph>
            <div style={{ textAlign: 'center', marginTop: 30 }}>
              <a
                href='https://www.hostdime.com.br/parceiros.php'
                target='_blank'
              >
                <Button
                  outlined
                  schema='lightPeriwinkle'
                  mr={[3]}
                  align='center'
                  style={{
                    color: '#737474',
                    fontWeight: 600,
                    lineHeight: 1.16,
                    letterSpacing: 1.25,
                    padding: '16px 16px 14px 16px',
                    width: 285,
                    textTransform: 'capitalize',
                    border: 'solid 1px rgba(0, 0, 0, 0.12)',
                    outline: 'none',
                    backgroundColor: 'rgba(98, 2, 238, 0)',
                  }}
                >
                  IR PARA NOSSO PARCEIRO
                </Button>
              </a>
            </div>
          </Column>
        </Row>
        <Hr
          lineColor='lightPeriwinkle'
          maxWidth={[328, '100%', 680, 680]}
          style={{
            opacity: 0.5,
            margin: '0 auto'
          }}
        />
        <Row
          flexDirection={['column']}
          css={content}
          mt={40}
        >
          <Column
            width={[1]}
          > 
            <H5>Se você já é nosso cliente, tem mais de 5 servidores dedicados e quer entrar na lista, nos envie sua solicitação.</H5>
            <div style={{ textAlign: 'center', marginTop: 30 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <HospedagemDeSitesForm
                  fields={fields}
                  formId={34}
                  submitText='Enviar'
                />
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Hospedagem de Sites' />
      <PageTemplate
        title='Hospedagem de Sites'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
